import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Grid from "@mui/material/Grid";
import GridItem from "../components/GridItem";
import TextField from "@mui/material/TextField";

export default function Encoding() {
  const [plainText, setPlainText] = React.useState("");
  const [encodedTextUrl, setEncodedTextUrl] = React.useState("");
  const [encodedTextBase64, setEncodedTextBase64] = React.useState("");
  const [encodedTextHex, setEncodedTextHex] = React.useState("");

  React.useEffect(() => {
    doEncode(plainText);
  }, [plainText]);


  const doEncode = (plainText) => {
    const buf = Buffer.from(plainText, "utf-8");
    setEncodedTextUrl(encodeURIComponent(plainText));
    setEncodedTextHex(buf.toString("hex"));
    setEncodedTextBase64(buf.toString("base64"));
  };
  
  //  React.useEffect(() => {
  //    doUrlDecode(encodedTextUrl);
  //  }, [encodedTextUrl]);

  //  React.useEffect(() => {
  //    doHexDecode(encodedTextHex);
  //  }, [encodedTextHex]);

  //  React.useEffect(() => {
  //    doBase64Decode(encodedTextBase64);
  //  }, [encodedTextBase64]);

  // const doUrlDecode = (encodedTextUrl) => {
  //   //check if encodedTextUrl is valid url
  //   if (!encodedTextUrl.match(/^[A-Za-z0-9+/%]+$/)) {
  //     // alert("Invalid URL string");
  //     return;
  //   }
  //   setPlainText(decodeURIComponent(encodedTextUrl));
  // };

  // const doHexDecode = (encodedTextHex) => {
  //   //check if encodedTextHex is valid hex
  //   if (!encodedTextHex.match(/^[0-9a-fA-F]+$/)) {
  //     // alert("Invalid HEX string");
  //     return;
  //   }
  //   setPlainText(Buffer.from(encodedTextHex, "hex").toString("utf-8"));
  // };

  // const doBase64Decode = (encodedTextBase64) => {
  //   //check if encodedTextBase64 is valid base64
  //   if (!encodedTextBase64.match(/^[A-Za-z0-9+/=]+$/)) {
  //     // alert("Invalid Base64 string");
  //     return;
  //   }
  //   setPlainText(Buffer.from(encodedTextBase64, "base64").toString("utf-8"));
  // }

  return (
    <Layout>
      <Helmet>
        <title>Online Encoding/Decoding Tool - URL, base64, hex</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GridItem sx={{ textAlign: "left" }}>
            <TextField
              id="plain-text"
              label="Plain Text"
              fullWidth
              variant="outlined"
              onChange={(event) => {
                setPlainText(event.target.value);
              }}
            />
          </GridItem>
        </Grid>
        <Grid item xs={12}>
          <GridItem>
            <TextField
              id="encoded-text-url"
              label="URL Encoded"
              disabled={true}
              fullWidth
              value={encodedTextUrl}
              variant="outlined"
              onChange={(event) => {
                setEncodedTextUrl(event.target.value);
              }}
            />
          </GridItem>

          <GridItem>
            <TextField
              id="encoded-text-hex"
              label="HEX Encoded"
              disabled={true}
              fullWidth
              value={encodedTextHex}
              variant="outlined"
              onChange={(event) => {
                setEncodedTextHex(event.target.value);
              }}
            />
          </GridItem>
          <GridItem>
            <TextField
              id="encoded-text-base64"
              label="Base64 Encoded"
              disabled={true}
              fullWidth
              value={encodedTextBase64}
              variant="outlined"
              onChange={(event) => {
                setEncodedTextBase64(event.target.value);
              }}
            />
          </GridItem>
        </Grid>
      </Grid>
    </Layout>
  );
}